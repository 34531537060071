<template>
  <validation-observer v-slot="{ valid }" tag="form" @submit.prevent="method === 'register' ? register() : login()">
    <v-card-title class="headline primary--text justify-center">
      {{ method === 'register' ? 'Register' : `Login with ${capitalizeFirstLetter(method)}` }}
    </v-card-title>
    <v-card-text
      v-if="errorMessage"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ errorMessage }}
      </v-alert>
    </v-card-text>
    <v-card-text v-if="['email', 'register'].includes(method)">
      <validation-provider
        v-slot="{ errors }"
        rules="required|email"
        name="Email"
      >
        <v-text-field
          v-model="form.email"
          label="Email Address"
          type="text"
          outlined
          prepend-icon="mdi-email"
          :error-messages="errors"
          autocomplete
          autofocus
          required
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        rules="required|min:6"
        name="Password"
        vid="confirmation"
      >
        <v-text-field
          v-model="form.password"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          outlined
          autocomplete
          required
          prepend-icon="mdi-key"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :error-messages="errors"
          @click:append="showPassword = !showPassword"
        />
      </validation-provider>
      <validation-provider
        v-if="method === 'register'"
        v-slot="{ errors }"
        rules="required|min:6|confirmed:confirmation"
        name="Password (Confirm)"
      >
        <v-text-field
          v-model="form.confirm"
          label="Password (Confirm)"
          :type="showPassword ? 'text' : 'password'"
          outlined
          required
          prepend-icon="mdi-key"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :error-messages="errors"
          @click:append="showPassword = !showPassword"
        />
      </validation-provider>
      <v-card-actions class="px-3">
        <v-btn
          text
          large
          @click="method = method === 'register' ? 'email' : 'register'"
        >
          {{ method === 'register' ? 'Login' : 'Register' }} with Email
        </v-btn>
        <v-spacer />
        <v-btn
          type="submit"
          color="primary"
          class="px-10"
          large
          :loading="loading"
          :disabled="!valid || loading"
        >
          Proceed
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <v-divider class="my-3" />
    <v-subheader class="justify-center">
      {{ method ? 'Other ' : '' }} Authentication Options
    </v-subheader>
    <v-card-actions>
      <v-row justify="space-around">
        <v-col v-if="!['email', 'register'].includes(method)" cols="12" sm="4">
          <v-btn
            large
            block
            outlined
            class="subtitle-1"
            @click="method = 'email'"
          >
            <v-icon class="mr-2">
              mdi-email-outline
            </v-icon>
            Email
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            large
            outlined
            block
            color="#d34836"
            class="subtitle-1"
            @click="google"
          >
            <v-icon class="mr-2">
              mdi-google
            </v-icon>
            Google
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
            large
            outlined
            block
            color="#3b5998"
            class="subtitle-1"
            @click="facebook"
          >
            <v-icon class="mr-2">
              mdi-facebook
            </v-icon>
            Facebook
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </validation-observer>
</template>

<script>
export default {
  name: 'Login',
  layout: 'auth',
  data: () => ({
    email: false,
    showPassword: false,
    loading: false,
    pendingCredential: null,
    form: {
      email: '',
      password: '',
      confirm: ''
    },
    errorMessage: null,
    method: 'email'
  }),
  watch: {
    form: {
      deep: true,
      handler() {
        this.errorMessage = null
      }
    },
    method() {
      this.errorMessage = null
    }
  },
  methods: {
    async login() {
      this.loading = true
      try {
        const { email, password } = this.form
        const { user } = await this.$auth.signInWithEmailAndPassword(email, password)
        await this.proceed(user)
      } catch (error) {
        this.handleError(error)
      }
      this.loading = false
    },
    async register() {
      this.loading = true
      try {
        const { email, password } = this.form
        await this.$auth.createUserWithEmailAndPassword(email, password)
        const { user } = await this.$auth.signInWithEmailAndPassword(email, password)
        await user.sendEmailVerification({ url: 'https://backend.mammutos.com' })
        this.proceed(user)
      } catch (error) {
        this.method === 'register' && this.handleError(error)
      }
      this.loading = false
    },
    async google() {
      this.method = 'google'
      const provider = new this.$firebase.auth.GoogleAuthProvider()
      try {
        const { user } = await this.$auth.signInWithPopup(provider)
        this.proceed(user)
      } catch (error) {
        this.method === 'google' && this.handleError(error)
      }
    },
    async facebook() {
      this.method = 'facebook'
      const provider = new this.$firebase.auth.FacebookAuthProvider()
      try {
        const { user } = await this.$auth.signInWithPopup(provider)
        this.proceed(user)
      } catch (error) {
        this.method === 'facebook' && this.handleError(error)
      }
    },
    async proceed(user) {
      this.pendingCredential && user.linkWithCredential(this.pendingCredential)
      await this.$store.dispatch('setUser', user)
      this.$router.push({ name: 'index' })
    },
    async handleError(error) {
      const { code, email, credential, message } = error
      let methods
      this.pendingCredential = null
      switch (code) {
        case 'auth/account-exists-with-different-credential':
          methods = await this.$auth.fetchSignInMethodsForEmail(email)
          this.errorMessage = `You already have an account setup with ${methods[0] === 'password' ? 'your email' : methods[0]}. Please sign in to link your accounts.`
          if (methods[0] === 'password') {
            this.method = 'email'
          }
          this.pendingCredential = credential
          break
        case 'auth/wrong-password':
          methods = await this.$auth.fetchSignInMethodsForEmail(this.form.email)
          if (methods.includes('password')) {
            this.errorMessage = 'Password invalid or account doesn\'t exist. Please try again.'
          } else if (methods.length) {
            this.errorMessage = `Your account doesn't have a password setup. Please sign in with ${methods[0]}.`
          }
          break
        case 'auth/user-not-found':
          this.errorMessage = 'Password invalid or account doesn\'t exist. Please try again.'
          break
        case 'auth/invalid-credential':
          this.errorMessage = 'Error getting verification code. Please try again.'
          break
        default:
          this.errorMessage = message
          break
      }
    }
  }
}
</script>
